import { useHistory, useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { Endpoints } from "../api/endpoints";
import {
  CategoryPrize,
  FilterData,
  Pagination,
  PrizeItemData,
  SinglePrize,
  UsePrizes,
} from "../types/Prizes";
import { useQuery } from "../utils/Common";
import { categoryActions } from "../types/Components";
import {
  getData,
  storeData,
  storeSessionData,
  removeSessionData,
  removeData,
} from "../utils/LocalStorage";
import { uniqBy } from "lodash";

export const usePrizes = (shouldFetch: boolean = false): UsePrizes => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const params = useParams() as any;
  const [chosenCategories, setChosenCategories] = useState<
    Array<CategoryPrize>
  >([]);
  const defaultPointsFilterValue = useMemo(() => [], []);

  const [returnPrev, setReturnPrev] = useState<boolean>(true);
  const [newPrizesChecked, setNewPrizesChecked] = useState(false);
  const [expiringPrizesChecked, setExpiringPrizesChecked] = useState(false);

  const changePage = useCallback(
    (page: number) => {
      const newPrizesFilter = getData("BASF-isNewPrizesFilter");
      const expiringPrizesFilter = getData("BASF-isExpiringPrizesFilter");
      setReturnPrev(false);
      const term = query?.get("term");
      history.push({
        pathname: location.pathname,
        search: `page=${page}${term ? `&term=${term}` : ""}${
          newPrizesFilter ? "&newPrizes=true" : ""
        }${expiringPrizesFilter ? "&expiringPrizes=true" : ""}`,
      });
      setPage(page);
    },
    [history, location.pathname, query]
  );

  const handleCategory = useCallback(
    (key: categoryActions, data?: CategoryPrize) => {
      const isCategory = chosenCategories.find(
        (el) => el.title === data?.title
      );
      if (!isCategory && data && key === categoryActions.set) {
        setChosenCategories((chosenCategories) => {
          const catArray = [...chosenCategories, data];
          storeData(catArray, "BASF-chosenCategories");
          return catArray;
        });
      }
      if (!data && key === categoryActions.set) {
        setChosenCategories([]);
        storeData([], "BASF-chosenCategories");
      }
      if (data && key === categoryActions.remove) {
        const filtered = chosenCategories.filter((el) => el.id !== data.id);
        setChosenCategories(filtered);
        storeData(filtered, "BASF-chosenCategories");
      }
      changePage(1);
    },
    [chosenCategories, changePage]
  );

  const initialPage = useMemo(
    () => parseInt(query?.get("page") ?? "1"),
    [query]
  );

  const [prizeList, setPrizeList] = useState<SinglePrize[]>([]);
  const [prizePagination, setPrizePagination] = useState<Pagination>({
    currentPageNumber: 0,
    totalCount: 0,
    itemNumberPerPage: 0,
    totalPages: 0,
  });
  const [page, setPage] = useState<number>(initialPage);
  const prizeLimit = 8;

  const [pointsFilter, setPointsFilter] = useState<Array<number>>(
    defaultPointsFilterValue
  );
  const [term, setTerm] = useState<string>("");
  const [sort, setSort] = useState<string>("");

  const sortOpt = useMemo(() => {
    if (!!sort && sort !== "sort_prizes") {
      return `&sortBy=${sort}`;
    }
    return "";
  }, [sort]);

  const categoriesOpt = useMemo(() => {
    if (chosenCategories.length) {
      return chosenCategories.reduce((acc, el) => {
        return acc + `&categories[]=${el.id}`;
      }, ``);
    }
    return "";
  }, [chosenCategories]);

  const termOpt = useMemo(() => {
    if (term !== "") {
      return `&term=${term}`;
    }
    return "";
  }, [term]);

  const returnPreviousOpt = useMemo(() => {
    if (returnPrev) {
      return `&returnPrevious=true`;
    }
    return "";
  }, [returnPrev]);

  const minPrice = useMemo(() => pointsFilter[0], [pointsFilter]);
  const maxPrice = useMemo(() => pointsFilter[1], [pointsFilter]);

  const priceRangeOpt = useMemo(() => {
    if (typeof minPrice === "number" || typeof maxPrice === "number") {
      return `&priceMin=${minPrice}&priceMax=${maxPrice}`;
    }
    return "";
  }, [maxPrice, minPrice]);

  const newPrizesOpt = useMemo(() => {
    if (newPrizesChecked) {
      return `&newPrizeFilter=true`;
    }
    return "";
  }, [newPrizesChecked]);

  const expiringPrizesOpt = useMemo(() => {
    if (expiringPrizesChecked) {
      return `&expiringPrizes=true`;
    }
    return "";
  }, [expiringPrizesChecked]);

  const url = useMemo(() => {
    return `${Endpoints.prizeSearch}?page=${page}&limit=${prizeLimit}${priceRangeOpt}${sortOpt}${categoriesOpt}${termOpt}${newPrizesOpt}${returnPreviousOpt}${expiringPrizesOpt}`;
  }, [
    categoriesOpt,
    newPrizesOpt,
    page,
    priceRangeOpt,
    sortOpt,
    termOpt,
    returnPreviousOpt,
    expiringPrizesOpt,
  ]);

  const { data: filter, error: filterError } = useSWR<FilterData, any>(
    shouldFetch ? Endpoints.prizeFilters : null
  );
  const { data: prizeData, error: prizeDataError } = useSWR<PrizeItemData, any>(
    () => (!!(shouldFetch && filter && maxPrice) ? url : null)
  );

  const handleSetNewPrizesFilter = useCallback((value) => {
    setNewPrizesChecked(value);
    storeData(value, "BASF-isNewPrizesFilter");
  }, []);

  const handleSetExpiringPrizesFilter = useCallback((value) => {
    setExpiringPrizesChecked(value);
    storeData(value, "BASF-isExpiringPrizesFilter");
  }, []);

  const handleSetPointsFilter = useCallback(
    (value) => {
      if (value && value.length) {
        setPointsFilter(value);
        storeData(value, "BASF-prizePointsFilter");
      } else {
        storeData([], "BASF-prizePointsFilter");
        setPointsFilter(defaultPointsFilterValue);
      }
    },
    [defaultPointsFilterValue]
  );

  const clearAllPrizeFilters = useCallback(() => {
    setTerm("");
    handleCategory(0);
    handleSetNewPrizesFilter(false);
    handleSetExpiringPrizesFilter(false);
    removeSessionData("basf-prize-position");
    removeData("BASF-isNewPrizesFilter");
    removeData("BASF-isExpiringPrizesFilter");
    if (!!filter?.priceSlider) {
      const { maxPrice, minPrice } = filter.priceSlider;
      handleSetPointsFilter([minPrice, maxPrice]);
    } else {
      handleSetPointsFilter([]);
    }
  }, [
    handleSetPointsFilter,
    filter?.priceSlider,
    handleCategory,
    handleSetNewPrizesFilter,
    handleSetExpiringPrizesFilter,
  ]);

  const setScrollPos = () => {
    storeSessionData(window.scrollY.toString(), "basf-prize-position");
  };

  useEffect(() => {
    const queryNewPrizesFilter = query?.get("newPrizes");
    if (!!queryNewPrizesFilter) {
      handleSetNewPrizesFilter(!!queryNewPrizesFilter);
    }
  }, [handleSetNewPrizesFilter, query]);

  useEffect(() => {
    const queryExpiringPrizesFilter = query?.get("expiringPrizes");
    if (!!queryExpiringPrizesFilter) {
      handleSetExpiringPrizesFilter(!!queryExpiringPrizesFilter);
    }
  }, [handleSetExpiringPrizesFilter, query]);

  useEffect(() => {
    const pointsFilter = getData("BASF-prizePointsFilter");
    if (!!pointsFilter && pointsFilter.length) {
      handleSetPointsFilter(pointsFilter);
    } else if (!!filter?.priceSlider) {
      const { maxPrice, minPrice } = filter.priceSlider;
      handleSetPointsFilter([minPrice, maxPrice]);
    }
  }, [filter?.priceSlider, handleSetPointsFilter]);

  const categoryInParams = useMemo(
    () =>
      !!params?.category
        ? (filter as FilterData)?.categories?.filter(
            (cat) => cat.id?.toString() === params?.category?.toString()
          )
        : null,
    [filter, params?.category]
  );

  useEffect(() => {
    if (initialPage !== page) setPage(initialPage);
    //eslint-disable-next-line
  }, [initialPage]);

  // this side effect combines both the selected categories and categories in url params
  useEffect(() => {
    const chosenCategoryFilters = getData("BASF-chosenCategories") || [];
    const paramsFilters = categoryInParams || [];
    const combinedFilters = [...chosenCategoryFilters, ...paramsFilters];

    // removes duplicates in the combined array before setting the final list to state
    const filteredCategoryFilters = uniqBy(combinedFilters, "id");

    if (!!filteredCategoryFilters.length) {
      setChosenCategories(filteredCategoryFilters);
    } else {
      setChosenCategories([]);
    }
  }, [categoryInParams]);

  useEffect(() => {
    const searchTerm = query?.get("term");
    if (!!searchTerm) setTerm(searchTerm);
  }, [query]);

  useEffect(() => {
    if (!!prizeData?.prizes) {
      if (page === 1) {
        setPrizeList(prizeData?.prizes);
      } else {
        setPrizeList((old) => [...old, ...prizeData.prizes]);
      }
    }
  }, [prizeData?.prizes, page]);

  useEffect(() => {
    if (!!prizeData?.pagination) setPrizePagination(prizeData?.pagination);
  }, [prizeData?.pagination]);

  const loading = useMemo(
    () => !prizeData && !prizeDataError && !filter && !filterError,
    [filter, filterError, prizeData, prizeDataError]
  );

  return {
    prizePagination,
    prizeList,
    prizeData,
    prizeDataError,
    loading,
    changePage,
    filter,
    handleSetPointsFilter,
    setTerm,
    setSort,
    term,
    chosenCategories,
    chosenPoints: pointsFilter,
    handleCategory,
    newPrizesChecked,
    setNewPrizesChecked,
    expiringPrizesChecked,
    setExpiringPrizesChecked,
    clearAllPrizeFilters,
    handleSetNewPrizesFilter,
    handleSetExpiringPrizesFilter,
    setScrollPos,
  };
};

export const useFilters = () => {
  const { data: filter, error: filterError } = useSWR(Endpoints.prizeFilters);

  const loading = useMemo(() => !filter && !filterError, [filter, filterError]);

  return {
    filter,
    loading,
    filterError,
    prizeCategories: filter?.categories ?? null,
  };
};
